<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Informações Gerais</span>

        <div class="row">
          <ed-input-tipo
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.intTipoImportacaoId"
            name="intTipoImportacaoId"
            label="Tipo de Importação"
            tipo="1453"
            rules="required"
            required
            :disabled="formData.intId ? true : false"
            @input="
              (x) => {
                formData.tipo_importacao = x;
              }
            "
          />

          <ed-input-auto-complete
              v-if="formData.intTipoImportacaoId == 1456"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.strValor.intPointId"
            label="Selecione uma lista de contato"
            name="intPointId"
            item-text="strNome"
            item-value="intId"
            route="Campanha/Lista"
            :filters="{
              intTipoListaId: 1429,
            }"
            :initializeItems="formData.intId ? true : false"
            rules="required"
          />

          <ed-input-file
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.arquivo"
            name="arquivo"
            label="Selecione um arquivo (excel ou csv)"
            accept="xls,xlsx"
            :disabled="formData.intId ? true : false"
          />

          <!-- <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.boolAtualizarExistente"
            name="boolAtualizarExistente"
            label="Atualizar dados existentes?"
            info="Se habilitar esse campo, o sistema vai atualizar os dados já existentes na base de dados"
          /> -->
        </div>
      </div>
    </div>
    <div class="row mt-10" v-if="bind.boolExibirProgressoImportacao">
      <div class="col-6 text-left">
        <h6>Progresso da importação</h6>
        <v-progress-linear
          v-model="bind.intTotalProcessado"
          color="orange"
          height="20"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
        <span style="font-size:11pt;" v-if="bind.strDescricaoProgresso"><i>{{ bind.strDescricaoProgresso }}</i></span>
        <p style="font-size:11pt;" v-if="false && bind.strDescricaoProgresso && formData.intTotalRegistro>10000"><i>O processo pode demorar um pouco, aproveite e vá tomar um café ou dar uma caminhada. Enquato isso importaremos seu arquivo :)</i></p>
        
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputSwitch,
  EdInputTipo,
  EdInputFile,
  EdButton,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  inject: ["bind","importarArquivo"],
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputSwitch,
    EdInputTipo,
    EdInputFile,
    EdButton,
    EdInputAutoComplete,
  },

  mounted() {},
  created() {},
  data() {
    return {
      arrayTipoAutenticacao: [
        { intId: "tls", strNome: "TLS" },
        //{ intId: "STARTTLS", strNome: "STARTTLS" },
        { intId: "ssl", strNome: "SSL" },
      ],
    };
  },
  watch: {
    'bind.intTotalProcessado'(){
      console.log('bind.intTotalProcessado => '+this.bind.intTotalProcessado)
    },
    'bind.strDescricaoProgresso'(){
      console.log('bind.strDescricaoProgresso => '+this.bind.strDescricaoProgresso)
    }
  },
  methods: {

  },
};
</script>
