<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-log" v-if="formData.intId">
        Logs de Importação
        <i :class="$utilidade.getIcone('historico') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent && strTab == 'tab-cadastro'"
    >
      <cadastro-geral
        :formData="formData"
        :key="'cadastro-' + $root.$session.versao"
      />

      <template slot="form-footer">
        <ed-button
          v-if="formData.tipo_importacao"
          label="Baixar modelo da planilha de importação"
          color="warning"
          :iconLeft="$utilidade.getIcone('download')"
          @click="downloadModelo"
        />

        <ed-button
          v-if="formData.intTipoStatusId == 1452"
          label="Importar Arquivo"
          color="success"
          :iconLeft="$utilidade.getIcone('upload')"
          @click="importarArquivo"
        />
      </template>
    </ed-form>
    <ed-log
      v-if="!this.$root.$session.loadingContent && strTab == 'tab-log'"
      :formData="formData"
      :key="'log-' + $root.$session.versao"
    />
  </div>
</template>

<script>
import { EdForm, EdButton } from "@/components/common/form";

import CadastroGeral from "./partials/geral.vue";
import EdLog from "./partials/log.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    intId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    EdButton,
    EdLog,
  },
  provide() {
    return {
      bind: this.bind,
      importarArquivo: this.importarArquivo,
    };
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    if (this.intId) {
      this.$root.$socket.unsubscribe("importacao." + this.intId);
    }
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      bind: {
        boolExibirProgressoImportacao: false,
        boolImportarArquivo: false,
        strDescricaoProgresso: null,
        intTotalProcessado: 0,
      },
      formData: {
        intId: 0,
        intTipoImportacaoId: null,
        arquivo: null,
        boolAtualizarExistente: 0,
        strValor: {
          intPointId: null,
        },
      },
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  computed: {},
  methods: {
    initialize() {
      if (!this.intId) return;

      let self = this;
      this.$root.$socket.subscribe(
        "importacao." + this.intId,
        "update",
        function (e) {
          console.log('update',e)
          self.exibirProgresso(e);
        }
      );

      this.$root.$dialog.loading(true);
      this.getImportacao();
    },

    exibirProgresso(e) {
      this.bind.boolExibirProgressoImportacao = true;
      let self = this;

      if (e.intTipoStatusId != 1450) {
        this.bind.intTotalProcessado = 100;
        this.bind.strDescricaoProgresso = "Processo finalizado com sucesso";
        this.getImportacao();
        setTimeout(() => {
          self.bind.boolExibirProgressoImportacao = false;
        }, 10000);
      } else {
        this.bind.intTotalProcessado =
          (Number(e.intTotalRegistroProcessado) / Number(e.intTotalRegistro)) *
          100;
        this.bind.strDescricaoProgresso = e.strDescricaoProgresso;
      }
    },

    getImportacao() {
      this.$root.$request
        .get("Configuracoes/Importacao", { intId: this.intId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.formData = Object.assign(objResponse.result, {});

            if (this.formData.intTipoStatusId == 1450) {
              this.exibirProgresso(this.formData);
            }
          }
        });
    },

    downloadModelo() {
      this.$root.$request.open("Configuracoes/Importacao/get", {
        intTipoImportacaoId: this.formData.intTipoImportacaoId,
        boolBaixarModelo: true,
      });
    },

    importarArquivo() {
      this.bind.boolImportarArquivo = true;
      this.salvar();
    },

    salvar() {
      this.$root.$dialog.loading(true);
      this.formData.boolImportarArquivo = this.bind.boolImportarArquivo;

      this.$root.$request
        .post("Configuracoes/Importacao", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);
          this.bind.boolImportarArquivo = false;

          if (objResponse.status == 200) {
            if (this.$route.name != "configuracoes.importacao.edit") {
              this.$router.push({
                name: "configuracoes.importacao.edit",
                params: { intId: objResponse.result.intId },
              });
              this.bind.boolExibirProgressoImportacao = true;
            } else {
              this.initialize();
            }
          }
        });
    },
  },
};
</script>
