<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
       strModel="System/LogImportacao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFilter"
      :loading="loading"
      @search="getLog"
      disabledRegisterRows
      disabledDeleteRows
      disabledEditRows
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: { EdTable },
  mounted() {
    this.getLog();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      formFiltros:{},
      arrayHeaders: [
        {
          text: "Linha do Arquivo",
          sortable: true,
          value: "intLinha",
        },
        {
          text: "Mensagem",
          sortable: true,
          collapse: 20,
          value: "strMensagem",
        }
      ],
      arrayRows: [],
      objPagination: null,
      formFilter:{
        intImportacaoId:this.formData.intId
      }
    };
  },
  methods: {

    getLog() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request.get("System/LogImportacao", this.formFilter).then((objResult) => {
        if (objResult.status == 200) {
          this.objPagination = objResult.result;
          this.loading = false;

          let arrayRows = [];

          objResult.result.data.forEach((objModel) => {


            let item = {
              intId: objModel._id,
              intLinha: objModel.intLinha,
              strMensagem: objModel.strMensagem,
              _item: objModel,
            };

            arrayRows.push(item);
          });

          this.arrayRows = arrayRows;
        }
      });
    },
  },
};
</script>
